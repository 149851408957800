import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { styled } from "styled-components";

import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import './WindowCard.scss'

import ExitCross from '../../../static/images/card-exit-cross-black.svg'
import ArrowLeft from '../../../static/images/card-arrow-carousel-left.svg'
import ArrowRight from '../../../static/images/card-arrow-carousel-right.svg'
import EnquireBackground from '../../../static/images/enquire-bg.webp'



function WindowCardContactFormInput({ id, placeholder, setState }) {

    function handleInputChange(event) {
        setState(event.target.value);
      };

    return (
        <div className="window-card-contact-input">
            <input 
                type="text" 
                name={id} 
                id={id} 
                placeholder={placeholder} 
                onChange={handleInputChange} 
            />
        </div>
    )
}

const WindowCardContactFormDiv = styled.div`
    background: url(${EnquireBackground}) no-repeat center center fixed;
`

export function WindowCardContactForm({ setActiveWindow }) {

    const [nameInput, setNameInput] = useState()
    const [emailInput, setEmailInput] = useState()
    const [phoneInput, setPhoneInput] = useState()

    const [isError, setIsError] = useState(false)

    const [ipInfo, setIpInfo] = useState(null);
    useEffect(() => {
        fetch('https://json.geoiplookup.io')
        .then(response => response.json())
        .then(data => {
            setIpInfo(data)
        })
    }, [])

    function showError() {
        setIsError(true)
        setTimeout(() => {
            setIsError(false)
        }, 300)
    }

    function sendData() {

        try {
            if (
                phoneInput && 
                emailInput && 
                nameInput && 
                emailInput.includes('@') && 
                emailInput.includes('.') && 
                nameInput.includes(' ') && 
                isValidPhoneNumber(phoneInput)
            ) {
                const phoneNumber = parsePhoneNumber(phoneInput)

                const apiUrl = 'https://azizi-back.visengine.dev/api/v1/form/send_data'
                
                const requestBody = {
                    "name": nameInput,
                    "email": emailInput,
                    "phone": phoneNumber.nationalNumber,
                    "phone_country_code": phoneNumber.countryCallingCode,
                    "country": ipInfo.country_name,
                    "city": ipInfo.city
                }
        
                const headers = {
                    'Content-Type': 'application/json'
                }
        
                fetch(apiUrl, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(requestBody),
                })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        console.error('Request failed with status:', response.status)
                        throw new Error('Request failed')
                    }
                })
                .then(data => {
                    alert('Thank you\nWe will get back to you soon')
                    // setActiveWindow(false)
                })
                .catch(() => showError())
            } else {
                showError()
            }
        } catch(error) {
            showError()
            console.log(error.message)
        }
    }
    
    return (
        <WindowCardContactFormDiv className="window-card-contact">
            <div className="window-card-contact-inner">
                <div className="window-card-contact-title">
                    <span className="window-card-contact-title-text">ENQUIRE NOW</span>
                </div>
                <div className="window-card-contact-desc">
                    <span className="window-card-contact-desc-text">
                        Register your interest, schedule a private showing or request a callback by filling out the form.
                    </span>
                </div>
                <WindowCardContactFormInput 
                    id="name-contact-form"
                    placeholder="Full Name *"
                    setState={setNameInput}
                />
                <WindowCardContactFormInput 
                    id="email-contact-form" 
                    placeholder="Email *" 
                    setState={setEmailInput}
                />
                <div className="window-card-contact-phone">
                    <PhoneInput
                        placeholder="Phone number *"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="AE"
                        value={phoneInput}
                        onChange={setPhoneInput}
                        withCountryCallingCode={true}
                    />
                </div>
                <div onClick={sendData} className={"window-card-contact-button " + (isError ? 'window-card-contact-button-shake' : '')}>
                    <span className="window-card-contact-button-text">SUBMIT</span>
                </div>
            </div>
        </WindowCardContactFormDiv>
    )
}


const CircleDiv = styled.div`
    background-color: ${({status}) => status ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"};
`

function WindowCardGalleryCircles({ previews, setImageIndex, activeCircle, setActiveCircle }) {
    return (
        <div className="window-card-gallery-circles">
            <div className="window-card-gallery-circles-inner">
                {
                    previews.map((preview) => (
                        <CircleDiv status={activeCircle === preview} className="window-card-gallery-circle" onClick={() => {
                            setActiveCircle(preview)
                            setImageIndex(previews.indexOf(preview))
                        }}></CircleDiv>
                    ))
                }
            </div>
        </div>
    )
}


const WindowCardGalleryDiv = styled.div`
    background-image: url(${({image}) => image});  
`

export function WindowCardGallery({ 
    cardActiveImageIndex, 
    images, 
    setActiveWindow, 
    setActiveCard,
    setCardImageIndex
}) {

    // ---------------------------------
    // *** Code needs to be replaced ***
    // ---------------------------------
    
    const [activeImage, setActiveImage] = useState()
    const [imageIndex, setImageIndex] = useState(cardActiveImageIndex)
    const [activeCircle, setActiveCircle] = useState()
    const [imagesList, setImagesList] = useState(images)

    useEffect(() => {
        let image = imagesList[imageIndex]
        setActiveImage(image)
        setActiveCircle(image)
        setCardImageIndex(imageIndex)
    }, [imageIndex])


    // Track updates from card
    useEffect(() => {
        setImagesList(images)
        setActiveImage(images[imageIndex])
    }, [images])

    useEffect(() => {
        setImageIndex(cardActiveImageIndex)
    }, [cardActiveImageIndex])


    const changeImage = (action) => {
        let nextIndex = imageIndex+action
        if (nextIndex > imagesList.length-1) {
            setImageIndex(0)
        } else if (nextIndex < 0) {
            setImageIndex(imagesList.length-1)
        } else {
            setImageIndex(nextIndex)
        }
    }
    
    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => changeImage(1),
        onSwipedRight: () => changeImage(-1),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    })

    return (
        <WindowCardGalleryDiv 
            className="window-card-gallery"
            image={activeImage}
            {...handlers}
        >
            <div 
                className="window-card-gallery-arrow window-card-gallery-arrow-left" 
                onClick={() => changeImage(-1)}
            >
                <img src={ArrowLeft} alt="arrow-left" />
            </div>
            <WindowCardGalleryCircles 
                previews={images}
                setImageIndex={setImageIndex}
                activeCircle={activeCircle}
                setActiveCircle={setActiveCircle}
            />
            <div 
                className="window-card-gallery-arrow window-card-gallery-arrow-right"
                onClick={() => changeImage(1)}
            >
                <img src={ArrowRight} alt="arrow-right" />
            </div>
            {/* <div className="window-card-gallery-back" /> */}
        </WindowCardGalleryDiv>
    )
}


const WindowCardDiv = styled.div`
    opacity: ${({status}) => status ? '1' : '0'};
    pointer-events: ${({status}) => status ? 'all' : 'none'} !important;
`

export default function WindowCard({ windowContent, activeWindow, setActiveWindow, setActiveCard }) {
    return (
        <div className="window-card">
            <WindowCardDiv 
                status={activeWindow && windowContent} 
                className="window-card-container"
            >
                <div 
                    className="window-card-cross" 
                    onClick={() => {
                        setActiveWindow(false)
                    }}
                >
                    <img src={ExitCross} alt="exit-cross" />
                </div>
                <div className="window-card-inner">
                    { windowContent && (
                        windowContent
                    ) }
                </div>
            </WindowCardDiv>
        </div>
    )
}