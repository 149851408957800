import MarkHospital from "../../../static/images/mark-hospital-small.svg";
import MarkSchool from "../../../static/images/mark-school-small.svg";
import MarkShopping from "../../../static/images/mark-shopping-small.svg";

// Projects
import MarkMina from "../../../static/images/projects/Mina.svg";
import MarkAlFurjan from "../../../static/images/projects/Al Furjan.svg";
import MarkAziziGrand from "../../../static/images/projects/Azizi Grand.svg";
import MarkRoyalBay from "../../../static/images/projects/Royal Bay.svg";
import MarkRiviera from "../../../static/images/projects/Riviera.svg";
import MarkAura from "../../../static/images/projects/Aura.svg";
import MarkVista from "../../../static/images/projects/Vista.svg";
import MarkBeachOasis from "../../../static/images/projects/Beach Oasis.svg";
import MarkParkAvenue from "../../../static/images/projects/Park Avenue.svg";
import MarkParkAvenue1 from "../../../static/images/projects/Park Avenue I.svg";
import MarkParkAvenue2 from "../../../static/images/projects/Park Avenue II.svg";
import MarkParkAvenue3 from "../../../static/images/projects/Park Avenue III.svg";
import MarkCreekViews from "../../../static/images/projects/Creek Views.svg";
import MarkCreekViews1 from "../../../static/images/projects/Creek Views I.svg";
import MarkCreekViews2 from "../../../static/images/projects/Creek Views II.svg";
import MarkCreekViews3 from "../../../static/images/projects/Creek Views III.svg";
import MarkAliyah from "../../../static/images/projects/Aliyah.svg";
import MarkVenice from "../../../static/images/projects/Venice.svg";
import MarkAziziTower from "../../../static/images/projects/Azizi Tower.svg";

// Project Previews
import MarkMinaPreview from "../../../static/images/previews/AZIZI-MINA-Palm-Jumeirah.jpg";
import MarkAlFurjanPreview from "../../../static/images/previews/AL-FURJAN-COMMUNITY-Al-Furjan.jpg";
import MarkAziziGrandPreview from "../../../static/images/previews/AZIZI-GRAND-Sport-City.jpg";
import MarkRoyalBayPreview from "../../../static/images/previews/AZIZI-ROYAL-BAY-Palm-Jumeirah.jpg";
import MarkRivieraPreview from "../../../static/images/previews/AZIZI-RIVIERA-MBR-City.jpg";
import MarkAuraPreview from "../../../static/images/previews/AZIZI-AURA-Jebel-Ali.jpg";
import MarkVistaPreview from "../../../static/images/previews/AZIZI-VISTA-Studio-City.jpg";
import MarkBeachOasisPreview from "../../../static/images/previews/AZIZI-BEACH-OASIS-Studio-City.jpg";
import MarkParkAvenuePreview from "../../../static/images/previews/AZIZI-PARK-AVENUE-MBR-City.jpg";
import MarkParkAvenue1Preview from "../../../static/images/previews/Park-Avenue-I.jpg";
import MarkParkAvenue2Preview from "../../../static/images/previews/Park-Avenue-II.jpg";
import MarkParkAvenue3Preview from "../../../static/images/previews/Park-Avenue-III.jpg";
import MarkCreekViewsPreview from "../../../static/images/previews/AZIZI-CREEK-VIEWS-DHCC.jpg";
import MarkCreekViews2Preview from "../../../static/images/previews/Creek-Views-II.jpg";
import MarkCreekViews3Preview from "../../../static/images/previews/Creek-Views-III.jpg";
import MarkAliyahPreview from "../../../static/images/previews/AZIZI-ALIYAH-DHCC.jpg";
import MarkVenicePreview from "../../../static/images/previews/Azizi-Venice.jpg";

// Additional images
import CubeArrowsImage from "../../../static/images/cube-arrows.png";

// Landmarks
import MarkJafza from "../../../static/images/landmarks_new/Jafza.svg";
import MarkDWC from "../../../static/images/landmarks_new/DXB.svg";
import MarkIbnBatuta from "../../../static/images/landmarks_new/Ibn Batuta.svg";
import MarkAinDubai from "../../../static/images/landmarks_new/Ain Dubai.svg";
import MarkMallOfTheEmirates from "../../../static/images/landmarks_new/Mall of the Emirates.svg";
import MarkDXB from "../../../static/images/landmarks_new/DXB-1.svg";
import MarkDubaiFrame from "../../../static/images/landmarks_new/Dubai Frame.svg";
import MarkBurjKhalifa from "../../../static/images/landmarks_new/Burj Khalifa.svg";
import MarkDubaiParksAndResorts from "../../../static/images/landmarks_new/Dubai Parks and Resorts.svg";

// Landmarks without icons
import MarkBurjAlArab from "../../../static/images/landmarks_labels_new/Burj Al Arab.svg";
import MarkJBR from "../../../static/images/landmarks_labels_new/JBR.svg";
import MarkTheMeydanHotel from "../../../static/images/landmarks_labels_new/The Meydan Hotel.svg";
import MarkDWTC from "../../../static/images/landmarks_labels_new/DWTC.svg";
import MarkGlobalVillage from "../../../static/images/landmarks_labels_new/Global Village.svg";
import MarkDubaiMarina from "../../../static/images/landmarks_labels_new/Dubai Marina.svg";
import MarkDowntownJebelAli from "../../../static/images/landmarks_labels_new/Downtown Jebel Ali.svg";
import MarkDubaiMall from "../../../static/images/landmarks_labels_new/Dubai Mall.svg";
import MarkDowntown from "../../../static/images/landmarks_labels_new/Downtown.svg";
import MarkPalmJumeirah from "../../../static/images/landmarks_labels_new/Palm Jumeirah.svg";
import MarkPalmJebelAli from "../../../static/images/landmarks_labels_new/Palm Jebel Ali.svg";

// Landmarks without labels
import MarkAinDubaiIcon from "../../../static/images/landmarks_icons/Ain Dubai.svg";
import MarkDubaiFrameIcon from "../../../static/images/landmarks_icons/Dubai Frame.svg";
import MarkDubaiParksAndResortsIcon from "../../../static/images/landmarks_icons/Dubai Parks and Resorts.svg";
import MarkDXBIcon from "../../../static/images/landmarks_icons/DXB.svg";
import MarkIbnBatutaIcon from "../../../static/images/landmarks_icons/Ibn Batuta.svg";
import MarkJafzaIcon from "../../../static/images/landmarks_icons/Jafza.svg";
import MarkMallOfTheEmiratesIcon from "../../../static/images/landmarks_icons/Mall of the Emirates.svg";
import MarkBurjKhalifaIcon from "../../../static/images/landmarks_icons/Burj Khalifa.svg";

import MarkBus1 from "../../../static/images/mark-bus-1.svg";
import MarkBus2 from "../../../static/images/mark-bus-2.svg";
import MarkBus3 from "../../../static/images/mark-bus-3.svg";

import MarkRoad11 from "../../../static/images/roads/road_11.svg";
import MarkRoad311 from "../../../static/images/roads/road_311.svg";
import MarkRoad611 from "../../../static/images/roads/road_611.svg";
import MarkRoad44 from "../../../static/images/roads/road_44.svg";
import MarkRoad66 from "../../../static/images/roads/lendmark-9_22.svg";
import MarkRoadD57 from "../../../static/images/roads/lendmark-2_22.svg";
import MarkRoadD72 from "../../../static/images/roads/lendmark-7_22.svg";
import MarkRoadD54 from "../../../static/images/roads/lendmark-3_22.svg";

import {
    // Infrastructure
    schools,
    hospitals,
    retails,

    // Polygons
    dubai_islands,
    palm_jumeirah,
    palm_jumeirah2,
    palm_jebel_ali,
    palm_jebel_ali2,
    palm_jebel_ali3,
    the_gardens,
    jumeirah_islands,
    jumeirah_village_circle,
    jumeirah_village_triangle,
    canal_front_residences,
    district_one,
    district_11,

    // Polylines
    bus_road_1,
    bus_road_2,
    bus_road_3,
    bus_road_1_stops,
    bus_road_2_stops,
    bus_road_3_stops,
    jumeirah_heights,
    international_city,
} from "./Coords";

export const infrastrMarkers = [
    {
        id: "Health",
        allCoords: hospitals,
        icon: MarkHospital,
    },
    {
        id: "Education",
        allCoords: schools,
        icon: MarkSchool,
    },
    {
        id: "Retail & Attractions",
        allCoords: retails,
        icon: MarkShopping,
    },
];

export const markers = [
    {
        id: "azizi_grand",
        checkIsInGroup: true,
        groupName: ["plotsforsale"],
        zoomLevel: null,
        coords: { lat: 25.037321836245212, lng: 55.20450609512032 },
        polygonCoords: [],
        icon: MarkAziziGrand,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI GRAND – Sport City",
            previews: [MarkAziziGrandPreview],
            desc: "A grandiosity encompassing the best of sustainability and sports. Strategically located on Al Fay Road, Azizi Grand is always at the heart of the action, yet at a comfortable distance away from the daily grind of the city. Most major attractions are just a short drive away. ",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/sport-city/azizi-grand",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 47, y: 18 },
    },
    {
        id: "al_furjan",
        checkIsInGroup: true,
        groupName: ["plotsforsale"],
        zoomLevel: null,
        coords: { lat: 25.026771086708205, lng: 55.14111646309774 },
        polygonCoords: [],
        icon: MarkAlFurjan,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AL FURJAN COMMUNITY – Al Furjan",
            previews: [MarkAlFurjanPreview],
            desc: "Azizi is developing numerous residential communities in Al Furjan, just minutes from the metro. Offering a strategic location, close to Expo 2020, Dubai Marina and Bluewaters Island, Al Furjan is an established community home to a variety of different properties.",
            video: null,
            paymentPlan: "https://azizidevelopments.com/en/dubai/al-furjan",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 42, y: 18 },
    },
    {
        id: "mina",
        checkIsInGroup: true,
        groupName: ["plotsforsale"],
        zoomLevel: null,
        coords: { lat: 25.127197130002884, lng: 55.15360136813863 },
        polygonCoords: [],
        icon: MarkMina,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI MINA – Palm Jumeirah",
            previews: [MarkMinaPreview],
            desc: "Mina is a luxury residential address in the east crescent of Palm Jumeirah with spectacular views of the Arabian Gulf. Beachfront living at its best, Mina is the perfect escape from the city and offers an idyllic location, close to a variety of five-star hotels, beach clubs, malls, cafes and restaurants.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/palm-jumeirah/azizi-mina",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 15, y: 18 },
    },
    {
        id: "royal_bay",
        checkIsInGroup: true,
        groupName: ["villas_for_sale"],
        zoomLevel: null,
        coords: { lat: 25.125398073549498, lng: 55.153282325810295 },
        polygonCoords: [],
        icon: MarkRoyalBay,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI ROYAL BAY – Palm Jumeirah",
            previews: [MarkRoyalBayPreview],
            desc: "ROYAL BAY, a sumptuous collection of fully serviced residential apartments situated on the crescent of Dubai’s iconic Palm Jumeirah, offers home buyers a unique chance to secure a place in one of the few remaining developments on the Eighth Wonder of the World. Cleverly angled at 45 degrees with spectacular views of the city’s skyline, the Atlantis Hotel and the Arabian sea, ROYAL BAY was designed to maximise light and provide stunning views from every room. The property enjoys the unique privilege of having direct access to a private beach.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/palm-jumeirah/royal-bay",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 42, y: 18 },
    },
    {
        id: "riviera",
        checkIsInGroup: true,
        groupName: ["villas_for_sale"],
        zoomLevel: null,
        coords: { lat: 25.17372276520713, lng: 55.3004228348803 },
        polygonCoords: [],
        icon: MarkRiviera,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI RIVIERA – MBR City",
            previews: [MarkRivieraPreview],
            desc: "Riviera is all about community living, evoking the classic Mediterranean Riviera lifestyle with a modern, contemporary outlook. Long strolls on the French-inspired boulevard, beautiful sunsets at the crystal lagoon, endless shopping experiences, and fine-dining feasts are all that you need for the ultimate lifestyle. Azizi Riviera is easily accessible via Business Bay, Sheikh Zayed Road, Al Khail Road, and Meydan Road. It is also well-connected with malls, cinemas, retail, schools, hospitals, and other modern necessities.",
            video: null,
            paymentPlan: null,
            floorPlans: null,
            MaterialBoardSpecification: null,
            buttons: [
                {
                    label: "Discover",
                    link: "https://azizidevelopments.com/en/dubai/meydan/riviera",
                },
                {
                    label: "Explore Azizi Riviera",
                    link: "https://360.visengine.com/portfolio/_dev/azizi_imp/",
                    image: CubeArrowsImage,
                    imgSize: "23px",
                    innerWidth: "180px",
                },
            ],
        },
        anchor: { x: 32, y: 18 },
    },
    {
        id: "aura",
        checkIsInGroup: true,
        groupName: ["villas_for_sale"],
        zoomLevel: null,
        coords: { lat: 24.978134485158375, lng: 55.091869396969315 },
        polygonCoords: [],
        icon: MarkAura,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI AURA – Jebel Ali",
            previews: [MarkAuraPreview],
            desc: "Aura is Azizi’s first completed residential community in Downtown Jebel, located on the metro line in Jebel Ali Free Zone, close to Expo 2020 and Ibn Battuta. Comprising of studios, one and two bedroom apartments, Aura offers a strategic location and excellent accessibility for residents living and working in the area.  Completed in 2020, Aura residents benefit from a swimming pool, landscaped gardens, a fully-equipped gymnasium, underground parking, 24 hour security and 9,000 sq ft dedicated to retail outlets for cafes, restaurants, convenience stores and shops. Aura is fully completed.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/jebel-ali/azizi-aura",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 25, y: 18 },
    },
    {
        id: "vista",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: null,
        coords: { lat: 25.035043752724153, lng: 55.23444856998641 },
        polygonCoords: [],
        icon: MarkVista,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI VISTA – Studio City",
            previews: [MarkVistaPreview],
            desc: "Azizi Vista welcomes you to be part of one of Dubai’s most desired residential areas – Dubai Studio City. Vista features modern apartments built with a sleek design and a welcoming atmosphere. Vista assures a perfect haven for young families and creative professionals looking for the right balance of living without having to compromise on priorities.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/studio-city/azizi-vista",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 25, y: 18 },
    },
    {
        id: "beach_oasis",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: null,
        coords: { lat: 25.037118371034808, lng: 55.23808706813546 },
        polygonCoords: [],
        icon: MarkBeachOasis,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI BEACH OASIS – Studio City",
            previews: [MarkBeachOasisPreview],
            desc: "Beach Oasis in Studio City is a vibrant residential community just outside the city, offering excellent connectivity to a wealth of amenities and attractions. This thriving modern community will be home to a beautiful lagoon-style swimming pool with a man-made beach.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/studio-city/beach-oasis",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 15, y: 18 },
    },
    {
        id: "park_avenue",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: null,
        maxZoomLevel: 14,
        coords: { lat: 25.16629192794668, lng: 55.30822250884192 },
        polygonCoords: [],
        icon: MarkParkAvenue,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI PARK AVENUE – MBR City",
            previews: [MarkParkAvenuePreview],
            desc: "Nestled on Meydan Avenue at the heart of Mohammed Bin Rashid City, Park Avenue comprises three contemporary low-rise buildings: Park Avenue Ι, Park Avenue ΙΙ, and Park Avenue ΙΙΙ. The development offers an idyllic address with lush green surroundings and is strategically placed close to Downtown Dubai and a plethora of world-class amenities and attractions.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/meydan/park-avenue",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 51, y: 18 },
    },
    {
        id: "park_avenue_1",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: 14,
        coords: { lat: 25.166197196834226, lng: 55.30788475856224 },
        polygonCoords: [],
        icon: MarkParkAvenue1,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "PARK AVENUE I",
            previews: [MarkParkAvenue1Preview],
            desc: "Park Avenue is a vibrant residential community in a prime location on the prestigious Meydan Avenue at the heart of MBR City. Surrounded by greenery and nature yet close to Downtown Dubai and a plethora of amenities and attractions, residents will enjoy the best of both worlds. Park Avenue is strategically positioned within close proximity to The Meydan Grandstand, The Meydan Hotel, The Meydan One Mall, schools and a variety of state-of-the-art sports and leisure facilities including a golf course and clubhouse, a tennis academy and cycling / running tracks.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/park-avenue/park-avenue-i",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 51, y: 18 },
    },
    {
        id: "park_avenue_2",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: 14,
        coords: { lat: 25.16505736542128, lng: 55.30543673451727 },
        polygonCoords: [],
        icon: MarkParkAvenue2,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "PARK AVENUE II",
            previews: [MarkParkAvenue2Preview],
            desc: "Park Avenue is a vibrant residential community in a prime location on the prestigious Meydan Avenue at the heart of MBR City. Surrounded by greenery and nature yet close to Downtown Dubai and a plethora of amenities and attractions, residents will enjoy the best of both worlds. Park Avenue is strategically positioned within close proximity to The Meydan Grandstand, The Meydan Hotel, The Meydan One Mall, schools and a variety of state-of-the-art sports and leisure facilities including a golf course and clubhouse, a tennis academy and cycling / running tracks.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/park-avenue/park-avenue-ii",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 51, y: 18 },
    },
    {
        id: "park_avenue_3",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: 14,
        coords: { lat: 25.15367527632961, lng: 55.28911887948742 },
        polygonCoords: [],
        icon: MarkParkAvenue3,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "PARK AVENUE III",
            previews: [MarkParkAvenue3Preview],
            desc: "Park Avenue III is a new launch project by Azizi, offering an exceptional lifestyle with all the facilities of modern urban living in one location. It’s just a stone’s throw from Meydan One Mall - the largest mall in the region, schools, colleges, a tennis academy, The Meydan Hotel and Grandstand, a world-class golf course designed by Peter Harradine, state-of-the-art cycling and running tracks. Azizi Park Avenue III features 89 high-end residential units and 13 premium retail stores. A strategic location in the heart of MBR city, exceptional amenities include a beautiful swimming pool, landscaped gardens, underground parking, and 24-hour security. Fitness enthusiasts will love the well-equipped gymnasium, sauna, and steam bath.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/park-avenue/park-avenue-iii",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 51, y: 18 },
    },
    {
        id: "creek_views",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: null,
        maxZoomLevel: 14,
        coords: { lat: 25.209217412751592, lng: 55.31538247978291 },
        polygonCoords: [],
        icon: MarkCreekViews,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI CREEK VIEWS – DHCC",
            previews: [MarkCreekViewsPreview],
            desc: "The new vibrant community in DHCC, overlooking the city’s beautiful creek, signifying Dubai’s transition from traditional views to contemporary reinvented future. With prime location and close proximity to Dubai Downtown, the three residential projects of Azizi Creek Views offer excellent connectivity.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/dubai-health-care-city/creek-views",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 50, y: 18 },
    },
    {
        id: "creek_views_1",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: 14,
        coords: { lat: 25.209217412751592, lng: 55.31538247978291 },
        polygonCoords: [],
        icon: MarkCreekViews1,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI CREEK VIEWS I – DHCC",
            previews: [MarkCreekViewsPreview],
            desc: "The new vibrant community in DHCC, overlooking the city’s beautiful creek, signifying Dubai’s transition from traditional views to contemporary reinvented future. With prime location and close proximity to Dubai Downtown, the three residential projects of Azizi Creek Views offer excellent connectivity.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/dubai-health-care-city/creek-views",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 49, y: 18 },
    },
    {
        id: "creek_views_2",
        checkIsInGroup: 13,
        groupName: ["apartments_for_sale"],
        zoomLevel: 14,
        coords: { lat: 25.20825021556999, lng: 55.31589722951515 },
        polygonCoords: [],
        icon: MarkCreekViews2,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI CREEK VIEWS II – DHCC",
            previews: [MarkCreekViews2Preview],
            desc: "The new vibrant community in DHCC, overlooking the city’s beautiful creek, signifying Dubai’s transition from traditional views to contemporary reinvented future. With prime location and close proximity to Dubai Downtown, the three residential projects of Azizi Creek Views offer excellent connectivity.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/dubai-health-care-city/creek-views-ii/construction-updates",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 51, y: 18 },
    },
    {
        id: "creek_views_3",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: 14,
        coords: { lat: 25.213623, lng: 55.320687 },
        polygonCoords: [],
        icon: MarkCreekViews3,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI CREEK VIEWS III – DHCC",
            previews: [MarkCreekViews3Preview],
            desc: "Creek Views III is the new addition to Azizi Creek Views in the heart of Dubai Healthcare City. Blending in modern life with a nature-friendly lifestyle, Creek Views III is where your quest for the perfect home finds an answer. The building features a total of 290 units including studios, one-bedroom, two-bedroom, and three-bedroom apartments, along with other lifestyle-centered amenities. <br><br>Creek Views III is home to a lively social area, a BBQ area, a yoga area, and a kids’ play area plus a Sauna & Steam, a gym, and two rejuvenating pools. The panoramic elevator adds to the exuberant experience of everyday living with great convenience.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/dubai-health-care-city/creek-views-iii",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 53, y: 18 },
    },
    {
        id: "aliyah",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: null,
        coords: { lat: 25.21332282526803, lng: 55.32078238924447 },
        polygonCoords: [],
        icon: MarkAliyah,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI ALIYAH – DHCC",
            previews: [MarkAliyahPreview],
            desc: "Dubai Healthcare City cultivates a versatile and health conscious lifestyle in the heart of Dubai. In close proximity to the creek, yet far enough from the hub of commerce and residence- Aliyah by Azizi is your sanctuary of tranquillity. Within this tastefully planned community, bask in an enticing social life, the delightful entertainment, finest business opportunities, healthcare facilities, leisure, family amenities, panoramic views of iconic Dubai attractions such as: Burj Khalifa and the majestic Dubai Creek.",
            video: null,
            paymentPlan:
                "https://azizidevelopments.com/en/dubai/dubai-health-care-city/azizi-aliyah",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 15, y: 18 },
    },
    {
        id: "venice",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: null,
        coords: { lat: 24.842260689890658, lng: 55.14075930292179 },
        polygonCoords: [],
        icon: MarkVenice,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: {
            title: "AZIZI VENICE – Dubai South",
            previews: [MarkVenicePreview],
            desc: "Dive into a world of unparalleled water-inspired living, unlike anything you've ever experienced, at Dubai's forthcoming legacy - a truly unparalleled luxury Crystal Lagoon community. Brace yourself to embark on a journey where opulence knows no boundaries, as you step into a haven of lavishness that stretches the limits of imagination. This is not just another destination, it's an uncharted masterpiece, waiting to be discovered.",
            video: null,
            paymentPlan: "http://www.azizivenice.com/",
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 30, y: 18 },
    },
    {
        id: "burj_khalifa",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.197162502862525, lng: 55.274415582913655 },
        polygonCoords: [],
        icon: MarkBurjKhalifa,
        activeIcon: MarkBurjKhalifaIcon,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "azizi_tower",
        checkIsInGroup: true,
        groupName: ["apartments_for_sale"],
        zoomLevel: null,
        coords: { lat: 25.223999, lng: 55.282926 },
        polygonCoords: [],
        icon: MarkAziziTower,
        activeIcon: null,
        clickable: {
            marker: false,
            polygon: true,
        },
        content: {
            title: "Azizi Tower",
            previews: [],
            desc: "",
            video: null,
            paymentPlan: null,
            floorPlans: null,
            MaterialBoardSpecification: null,
        },
        anchor: { x: 47, y: 18 },
    },
    {
        id: "dwc",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 24.88592125750257, lng: 55.158812627995594 },
        polygonCoords: [],
        icon: MarkDWC,
        activeIcon: MarkDXBIcon,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "mall_of_the_emirates",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.118077698944138, lng: 55.2005865388732 },
        polygonCoords: [],
        icon: MarkMallOfTheEmirates,
        activeIcon: MarkMallOfTheEmiratesIcon,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "ain_dubai",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.078760203180344, lng: 55.123433199104554 },
        polygonCoords: [],
        icon: MarkAinDubai,
        activeIcon: MarkAinDubaiIcon,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "dxb",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.25315504776068, lng: 55.365694257077394 },
        polygonCoords: [],
        icon: MarkDXB,
        activeIcon: MarkDXBIcon,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "jafza",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 24.961821229149496, lng: 55.05393352696449 },
        polygonCoords: [],
        icon: MarkJafza,
        activeIcon: MarkJafzaIcon,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "ibn_batuta",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.044799900137903, lng: 55.12037229697157 },
        polygonCoords: [],
        icon: MarkIbnBatuta,
        activeIcon: MarkIbnBatutaIcon,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "dubai_frame",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.235655980687195, lng: 55.300308708619674 },
        polygonCoords: [],
        icon: MarkDubaiFrame,
        activeIcon: MarkDubaiFrameIcon,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "dubai_parks_and_resorts",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 24.92415030011686, lng: 55.00655593505823 },
        polygonCoords: [],
        icon: MarkDubaiParksAndResorts,
        activeIcon: MarkDubaiParksAndResortsIcon,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "burj_al_arab",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.140777850316645, lng: 55.18530552826253 },
        polygonCoords: [],
        icon: MarkBurjAlArab,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "jbr",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.08971059291129, lng: 55.13731712506719 },
        polygonCoords: [],
        icon: MarkJBR,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "the_meydan_hotel",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.13444791082133, lng: 55.28319501978956 },
        polygonCoords: [],
        icon: MarkTheMeydanHotel,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "dwtc",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.229867497620493, lng: 55.28101980483302 },
        polygonCoords: [],
        icon: MarkDWTC,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "global_village",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.071909805420216, lng: 55.30875713576341 },
        polygonCoords: [],
        icon: MarkGlobalVillage,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "dubai_marina",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.0806624609459, lng: 55.140655544267126 },
        polygonCoords: [],
        icon: MarkDubaiMarina,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "downtown_jebel_ali",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 24.943270052046472, lng: 55.05665535439852 },
        polygonCoords: [],
        icon: MarkDowntownJebelAli,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "dubai_mall",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.194503678925244, lng: 55.28613195042234 },
        polygonCoords: [],
        icon: MarkDubaiMall,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "downtown",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.184574133831, lng: 55.26378444356823 },
        polygonCoords: [],
        icon: MarkDowntown,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "palm_jumeirah",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 25.12282272602143, lng: 55.131482348357075 },
        polygonCoords: [],
        icon: MarkPalmJumeirah,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "palm_jebel_ali",
        checkIsInGroup: true,
        groupName: ["landmarks"],
        zoomLevel: null,
        coords: { lat: 24.999441278059187, lng: 54.99296192617282 },
        polygonCoords: [],
        icon: MarkPalmJebelAli,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_11_1",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.059285647695802, lng: 55.127586532058714 },
        polygonCoords: [],
        icon: MarkRoad11,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_11_2",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.152370882077882, lng: 55.22569421945486 },
        polygonCoords: [],
        icon: MarkRoad11,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_11_3",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 24.94804663927239, lng: 55.06515203309374 },
        polygonCoords: [],
        icon: MarkRoad11,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_311_1",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.079873518310592, lng: 55.310811200930765 },
        polygonCoords: [],
        icon: MarkRoad311,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_311_2",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.182758883069617, lng: 55.39776881278631 },
        polygonCoords: [],
        icon: MarkRoad311,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_311_3",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 24.908271614614133, lng: 55.091141990324815 },
        polygonCoords: [],
        icon: MarkRoad311,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_611_1",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.124557880396765, lng: 55.44576978163089 },
        polygonCoords: [],
        icon: MarkRoad611,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_611_2",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 24.866674790593034, lng: 55.1820704313196 },
        polygonCoords: [],
        icon: MarkRoad611,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_611_3",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.02070013625166, lng: 55.28217271508388 },
        polygonCoords: [],
        icon: MarkRoad611,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_44_1",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.173086843172452, lng: 55.271892748574146 },
        polygonCoords: [],
        icon: MarkRoad44,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_44_2",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.18572460825172, lng: 55.341783579902156 },
        polygonCoords: [],
        icon: MarkRoad44,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
    {
        id: "road_44_3",
        checkIsInGroup: false,
        groupName: [],
        zoomLevel: null,
        coords: { lat: 25.05729581959254, lng: 55.19539136670589 },
        polygonCoords: [],
        icon: MarkRoad44,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true,
        },
        content: null,
    },
];

export const polygons = [
    {
        id: "dubai_islands",
        allPaths: [dubai_islands],
        clickable: false,
    },
    {
        id: "palm_jumeirah",
        allPaths: [palm_jumeirah, palm_jumeirah2],
        clickable: true,
        coords: { lat: 25.13580471755381, lng: 55.12488577526935 },
    },
    {
        id: "palm_jebel_ali",
        allPaths: [palm_jebel_ali, palm_jebel_ali2, palm_jebel_ali3],
        clickable: true,
        coords: { lat: 25.02889554719483, lng: 54.97125678828401 },
    },
    {
        id: "the_gardens",
        allPaths: [the_gardens],
        clickable: true,
        coords: { lat: 25.038020464530135, lng: 55.12532137813933 },
    },
    {
        id: "jumeirah_islands",
        allPaths: [jumeirah_islands],
        clickable: false,
    },
    {
        id: "jumeirah_village_circle",
        allPaths: [jumeirah_village_circle],
        clickable: false,
    },
    {
        id: "jumeirah_village_triangle",
        allPaths: [jumeirah_village_triangle],
        clickable: false,
    },
];

export const polylines = [
    {
        id: "bus_station_1",
        path: bus_road_1,
        stops: bus_road_1_stops,
        icon: MarkBus1,
        color: "#EC5650",
    },
    {
        id: "bus_station_2",
        path: bus_road_2,
        stops: bus_road_2_stops,
        icon: MarkBus2,
        color: "#49CD57",
    },
    {
        id: "bus_station_3",
        path: bus_road_3,
        stops: bus_road_3_stops,
        icon: MarkBus3,
        color: "#F26E24",
    },
];
