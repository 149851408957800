import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    GoogleMap,
    MarkerF,
    PolygonF,
    InfoWindowF,
    DirectionsRenderer,
    MarkerClustererF,
    CircleF,
    PolylineF,
} from "@react-google-maps/api";

import "./Map.scss";

import CarIcon from "../../../static/images/car-icon.png";
import Mark5KM from "../../../static/images/mark-5km.svg";
import Mark10KM from "../../../static/images/mark-10km.svg";
import Mark15KM from "../../../static/images/mark-15km.svg";
import Mark20KM from "../../../static/images/mark-20km.svg";

import { infrastrMarkers, markers, polygons, polylines } from "./Markers";

function MapMarker({
    position,
    options,
    icon,
    activeIcon,

    onMouseOver,
    onMouseOut,
    onClick,

    routeMarkers,
}) {
    const markerRef = useRef(null);

    const handleMarkerLoad = (marker) => {
        markerRef.current = marker;

        markerRef.current.setOpacity(0);
    };

    const handleMarkerClick = () => {
        // nothing
    };

    const steps = [1, 0.9, 0.8, 0.7, 0.6, 0.5];
    const stepsReversed = [...steps].reverse();
    let stepTime = 20;

    const changeOpacity = (stepsInput) => {
        if (
            !(
                markerRef.current.getOpacity() ==
                stepsInput[stepsInput.length - 1]
            )
        ) {
            let currentTime = stepTime;
            for (let step of stepsInput) {
                setTimeout(() => {
                    markerRef.current.setOpacity(step);
                }, currentTime);
                currentTime = currentTime + stepTime;
            }
        }
    };

    useEffect(() => {
        if (routeMarkers.length !== 0) {
            if (routeMarkers.includes(position)) {
                changeOpacity(stepsReversed);
            } else {
                changeOpacity(steps);
            }
        } else {
            changeOpacity(stepsReversed);
        }
    }, [routeMarkers]);

    const [isCursorHover, setIsCursorHover] = useState(false);
    useEffect(() => {
        if (activeIcon) {
            if (isCursorHover) {
                markerRef.current.setOptions({
                    icon: icon,
                });
            } else {
                markerRef.current.setOptions({
                    icon: activeIcon,
                });
            }
        }
    }, [isCursorHover]);

    return (
        <MarkerF
            position={position}
            options={{
                ...options,
            }}
            icon={activeIcon ? activeIcon : icon}
            onMouseOver={() => {
                onMouseOver();
                setIsCursorHover(true);
            }}
            onMouseOut={() => {
                onMouseOut();
                setIsCursorHover(false);
            }}
            onClick={() => {
                onClick();
                handleMarkerClick();
            }}
            onLoad={handleMarkerLoad}
        >
            {/* {
                activeMarker === id && (
                    <InfoWindowF options={{disableAutoPan: true}}  >
                        <div>{1}</div>
                    </InfoWindowF>
                )
            } */}
        </MarkerF>
    );
}

export default function Map({
    isLoaded,
    checkboxFiltersRadio,
    setCurrentActiveDropdown,
    activeCard,
    setActiveCard,
    setCardContent,
    activeWindow,
    setActiveWindow,
    directionSwitchChecked,
    setDirectionSwitchChecked,
    radiusSwitchChecked,
    setRadiusSwitchChecked,
    masterCommunitiesChecked,
    mapTypeId,
}) {
    const center = useMemo(
        () => ({ lat: 25.10243294157251, lng: 55.21514253938479 }),
        []
    );

    const [routeMarkers, setRouteMarkers] = useState([]);
    const [circlesCenter, setCirclesCenter] = useState(null);

    const [mapZoom, setMapZoom] = useState();
    const handleZoomChange = () => {
        if (mapRef.current) {
            setMapZoom(mapRef.current.getZoom());
        }
    };

    const mapRef = useRef();

    const [directions, setDirections] = useState();
    const onLoad = useCallback((map) => {
        mapRef.current = map;
        mapRef.current.addListener("zoom_changed", handleZoomChange);

        mapRef.google = window.google;
        mapRef.directionsService = new mapRef.google.maps.DirectionsService();
        mapRef.directionsRenderer = new mapRef.google.maps.DirectionsRenderer();
    }, []);

    const fetchDirections = (coords) => {
        if (!radiusSwitchChecked && coords.lat !== 25.22944996319096) {
            // additional checking, second condition for the project "The World"
            if (routeMarkers.length === 1 && !routeMarkers.includes(coords)) {
                mapRef.directionsService.route(
                    {
                        origin: routeMarkers[0],
                        destination: coords,
                        travelMode: mapRef.google.maps.TravelMode.DRIVING,
                    },
                    (result, status) => {
                        if (status === "OK" && result) {
                            const path = result.routes[0].overview_path;
                            const midPointIndex = Math.floor(path.length / 2);
                            mapRef.midPoint = path[midPointIndex];

                            mapRef.current.setOptions({
                                heading: -45,
                                zoom: 11,
                            });
                            mapRef.current.panTo(mapRef.midPoint);

                            setDirections(result);
                            setRouteMarkers([...routeMarkers, coords]);
                        }
                    }
                );
            } else if (routeMarkers.length === 2) {
                setDirections(undefined);
                setRouteMarkers([coords]);
            } else {
                setRouteMarkers([coords]);
            }
        }
    };

    const [activeMarker, setActiveMarker] = useState(null);
    const [activeMarker2, setActiveMarker2] = useState(null);
    const openMarkerWindow = (id, data) => {
        if (activeCard === id) {
            setActiveCard(null);
        } else {
            setCardContent(data);
            setActiveCard(id);
            setActiveWindow(null);
        }
        setCurrentActiveDropdown("");
    };

    const handleMarkerClick = (id, coords, data, type) => {
        if (directionSwitchChecked) {
            fetchDirections(coords);
        } else if (radiusSwitchChecked && type !== "infrastr") {
            if (!directionSwitchChecked) {
                // additional checking
                setCirclesCenter(coords);
                setRouteMarkers([coords]);
            }
        } else if (data) {
            openMarkerWindow(id, data);
        }
    };

    // When the switch was changed
    useEffect(() => {
        try {
            if (!directionSwitchChecked) {
                setDirections(undefined);
                setRouteMarkers([]);
            } else {
                setRadiusSwitchChecked(false);
            }
        } catch {}
    }, [directionSwitchChecked]);

    useEffect(() => {
        try {
            if (!radiusSwitchChecked) {
                setCirclesCenter(null);
            } else {
                setDirectionSwitchChecked(false);
            }
        } catch {}
    }, [radiusSwitchChecked]);

    // Circles
    useEffect(() => {
        try {
            if (!circlesCenter) {
                setRouteMarkers([]);
            }
            mapRef.current.panTo(circlesCenter);
        } catch {}
    }, [circlesCenter]);

    // Calculations
    const earthRadius = 6378137;
    const metersToLatLng = (
        distanceInMetersLat,
        distanceInMetersLng,
        latitude,
        longitude
    ) => {
        // Distance from meters to radians
        const distanceInRadiansLat = distanceInMetersLat / earthRadius;
        const distanceInRadiansLng = distanceInMetersLng / earthRadius;

        // Lat and Lng to radians
        const latitudeInRadians = (latitude * Math.PI) / 180;
        const longitudeInRadians = (longitude * Math.PI) / 180;

        // Calcule new lng and lat
        const newLatitudeInRadians = latitudeInRadians + distanceInRadiansLat;
        const newLongitudeInRadians = longitudeInRadians + distanceInRadiansLng;

        // Convert from rad to degrees
        const newLatitude = (newLatitudeInRadians * 180) / Math.PI;
        const newLongitude = (newLongitudeInRadians * 180) / Math.PI;

        return { lat: newLatitude, lng: newLongitude };
    };

    return (
        <div className="map">
            {isLoaded ? (
                <GoogleMap
                    zoom={12}
                    center={center}
                    mapContainerClassName="map-container"
                    heading={-45}
                    onLoad={onLoad}
                    onClick={() => {
                        setDirections(undefined);
                        setCirclesCenter(null);
                        setRouteMarkers([]);
                        setCurrentActiveDropdown("");
                        setActiveMarker2(null);
                        setActiveCard(false);
                        setActiveWindow(false);
                    }}
                    onDrag={() => {
                        setCurrentActiveDropdown("");
                        setActiveWindow(false);
                    }}
                    onZoomChanged={() => {
                        setCurrentActiveDropdown("");
                        setActiveWindow(false);
                    }}
                    options={{
                        mapId: "4ad36bde494e2f3f",
                        disableDefaultUI: true,
                        restriction: {
                            latLngBounds: {
                                north: 26.261815089520567,
                                south: 24.210864776343406,
                                west: 54.259044521019725,
                                east: 57.016234711938566,
                            },
                            strictBounds: false,
                        },
                        mapTypeId: mapTypeId,
                    }}
                >
                    {/* Infrastructure Markers Mapping */}
                    {infrastrMarkers.map(
                        ({ id, allCoords, icon, label }) =>
                            checkboxFiltersRadio.includes(id) &&
                            allCoords.map((coords) => (
                                <MarkerF
                                    position={{
                                        lat: coords[0],
                                        lng: coords[1],
                                    }}
                                    icon={icon}
                                    onClick={() =>
                                        handleMarkerClick(
                                            1,
                                            { lat: coords[0], lng: coords[1] },
                                            null,
                                            "infrastr"
                                        )
                                    }
                                    routeMarkers={routeMarkers}
                                    options={{ clickable: true }}
                                    onMouseOver={() => setActiveMarker(coords)}
                                    onMouseOut={() => setActiveMarker(null)}
                                >
                                    {(activeMarker === coords ||
                                        activeMarker2 === coords) && (
                                        <InfoWindowF
                                            options={{ disableAutoPan: true }}
                                        >
                                            <div>
                                                <span>
                                                    {coords.name
                                                        ? coords.name
                                                        : id}
                                                </span>
                                            </div>
                                        </InfoWindowF>
                                    )}
                                </MarkerF>
                            ))
                    )}

                    {/* Markers Mapping */}
                    {markers.map(
                        ({
                            id,
                            checkIsInGroup,
                            groupName,
                            zoomLevel,
                            maxZoomLevel,
                            coords,
                            polygonCoords,
                            icon,
                            activeIcon,
                            clickable,
                            content,
                            anchor,
                        }) =>
                            ((checkIsInGroup &&
                                groupName
                                    .map((name) =>
                                        checkboxFiltersRadio.includes(name)
                                    )
                                    .every((item) => item === true)) ||
                                !checkIsInGroup) &&
                            (mapZoom > zoomLevel || !zoomLevel) &&
                            (mapZoom <= maxZoomLevel || !maxZoomLevel) && (
                                <MapMarker
                                    position={coords}
                                    options={{
                                        clickable: clickable.marker,
                                        zIndex: 10,
                                    }}
                                    icon={{
                                        url: icon,
                                        anchor: anchor
                                            ? new window.google.maps.Point(
                                                  anchor.x,
                                                  anchor.y
                                              )
                                            : null,
                                    }}
                                    activeIcon={activeIcon}
                                    onMouseOver={() => setActiveMarker(id)}
                                    onMouseOut={() => setActiveMarker(null)}
                                    onClick={() =>
                                        handleMarkerClick(
                                            id,
                                            coords,
                                            !(
                                                directionSwitchChecked ||
                                                radiusSwitchChecked
                                            ) && content
                                        )
                                    } // additional checking
                                    routeMarkers={routeMarkers}
                                />
                            )
                    )}

                    {/* Polygons Mapping */}
                    {markers.map(
                        ({
                            id,
                            checkIsInGroup,
                            groupName,
                            coords,
                            polygonCoords,
                            icon,
                            activeIcon,
                            clickable,
                            content,
                        }) =>
                            checkIsInGroup &&
                            masterCommunitiesChecked &&
                            groupName
                                .map((name) =>
                                    checkboxFiltersRadio.includes(name)
                                )
                                .every((item) => item === true) &&
                            polygonCoords &&
                            polygonCoords.map((paths) => (
                                <PolygonF
                                    paths={paths}
                                    options={polygonOptions(clickable.polygon)}
                                    onMouseOver={() => setActiveMarker(id)}
                                    onMouseOut={() => setActiveMarker(null)}
                                    onClick={() =>
                                        handleMarkerClick(id, coords, content)
                                    }
                                />
                            ))
                    )}

                    {/* Polyline Mapping */}
                    {checkboxFiltersRadio.includes("transport") &&
                        polylines.map(({ id, path, stops, icon, color }) => (
                            <>
                                <PolylineF
                                    path={path}
                                    options={{
                                        strokeColor: color,
                                        strokeOpacity: 1,
                                        strokeWeight: 4,
                                        clickable: false,
                                    }}
                                />
                                {stops.map(({ name, coords }) => (
                                    <MarkerF
                                        position={coords}
                                        icon={icon}
                                        options={{ clickable: true, zIndex: 1 }}
                                        onMouseOver={() =>
                                            setActiveMarker(coords)
                                        }
                                        onMouseOut={() => setActiveMarker(null)}
                                        onClick={() => setActiveMarker2(coords)}
                                        routeMarkers={routeMarkers}
                                    >
                                        {(activeMarker === coords ||
                                            activeMarker2 === coords) && (
                                            <InfoWindowF
                                                options={{
                                                    disableAutoPan: true,
                                                }}
                                            >
                                                <div>
                                                    <span>{name}</span>
                                                </div>
                                            </InfoWindowF>
                                        )}
                                    </MarkerF>
                                ))}
                            </>
                        ))}

                    {/* Drawing Directions */}
                    {directions && directionSwitchChecked && (
                        <>
                            <DirectionsRenderer
                                directions={directions}
                                options={{
                                    suppressMarkers: true,
                                    polylineOptions: {
                                        zIndex: 500,
                                        strokeColor: "#FFFFFF",
                                        strokeWeight: 4,
                                    },
                                    disableAutoPan: true,
                                    preserveViewport: true,
                                }}
                            />

                            <InfoWindowF position={mapRef.midPoint}>
                                <div className="directions-infowindow">
                                    <img src={CarIcon} alt="car-icon" />
                                    <span>
                                        {directions.routes[0].legs[0].distance
                                            .text +
                                            " | " +
                                            directions.routes[0].legs[0]
                                                .duration.text +
                                            " "}
                                    </span>
                                </div>
                            </InfoWindowF>
                        </>
                    )}

                    {/* Drawing Circles */}
                    {circlesCenter && (
                        <>
                            <CircleF
                                center={circlesCenter}
                                radius={5000}
                                options={circleOptions}
                            />
                            <CircleF
                                center={circlesCenter}
                                radius={10000}
                                options={circleOptions}
                            />
                            <CircleF
                                center={circlesCenter}
                                radius={15000}
                                options={circleOptions}
                            />
                            <CircleF
                                center={circlesCenter}
                                radius={20000}
                                options={circleOptions}
                            />

                            <MarkerF
                                icon={Mark5KM}
                                position={metersToLatLng(
                                    3750,
                                    -3750,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                            <MarkerF
                                icon={Mark10KM}
                                position={metersToLatLng(
                                    7500,
                                    -7500,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                            <MarkerF
                                icon={Mark15KM}
                                position={metersToLatLng(
                                    11250,
                                    -11250,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                            <MarkerF
                                icon={Mark20KM}
                                position={metersToLatLng(
                                    15000,
                                    -15000,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />

                            <MarkerF
                                icon={Mark10KM}
                                position={metersToLatLng(
                                    6750,
                                    6750,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                            <MarkerF
                                icon={Mark20KM}
                                position={metersToLatLng(
                                    14000,
                                    14000,
                                    circlesCenter["lat"],
                                    circlesCenter["lng"]
                                )}
                                options={{ clickable: false }}
                            />
                        </>
                    )}
                </GoogleMap>
            ) : (
                <h1>Loading...</h1>
            )}
        </div>
    );
}

const polygonOptions = (clickable) => ({
    fillColor: "rgba(250, 133, 79, 0.5)",
    fillOpacity: 1,
    strokeColor: "rgba(250, 133, 79, 0.9)",
    strokeOpacity: 0.85,
    strokeWeight: 2,
    clickable: clickable,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
});

const circleOptions = {
    strokeOpacity: 0.5,
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    fillOpacity: 0.075,
    strokeColor: "#FFFFFF",
    fillColor: "#FFFFFF",
};
